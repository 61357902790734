import { SuspensionType } from "../../../../core/interfaces/suspension-type.interface";

export class LoadSuspensionTypes {
  public static readonly type = "[Suspension Types] load suspension types";
}

export class AddSuspensionType {
  public static readonly type = "[Suspension Types] add suspension type";
  constructor(
    public payload: SuspensionType,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class UpdateSuspensionType {
  public static readonly type = "[Suspension Types] update suspension type";
  constructor(
    public payload: SuspensionType,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class RemoveSuspensionType {
  public static readonly type = "[Suspension Types] remove suspension type";
  constructor(
    public suspensionTypeId: string,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}
